<template>
  <div class="postbackReport">
    <!-- <div class="main-Title bgff"><h2>Postback Report</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-form v-model="postbackReport" label-width="100px" size="mini">
          <el-row>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item label="TranscationId(s)" label-width="110px" class="mb5">
                <el-input
                  v-model="postbackReport.transactionIds"
                  placeholder="transactionIds eg:123 or 123,456"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item label="OfferId(s)" label-width="90px" class="mb5">
                <el-input
                  v-model="postbackReport.offerIds"
                  placeholder="offerIds: eg. 2206264 or 2206264,1567396"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item label="Affiliate(s)" label-width="90px" class="mb5">
                <group-select
                  v-model="postbackReport.affiliateIds"
                  :data-source="affiliatesGroupList"
                  multiple
                  reserve-keyword
                  :loading="affiliateLoading"
                  clearable
                  filterable
                  collapse-tags
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item label="Source(s)" label-width="100px" class="mb5">
                <group-select
                  v-model="postbackReport.sourceIds"
                  :data-source="sourcesList"
                  multiple
                  :loading="sourceLoading"
                  clearable
                  filterable
                  collapse-tags
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
              <el-form-item label="TimeZone" label-width="70px" class="mb5">
                <el-select v-model="postbackReport.timezone" class="w100">
                  <el-option
                    v-for="item in timezoneOption"
                    :key="item.value"
                    :value="item.number"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item label="Date" label-width="60px" class="mb5">
                <el-date-picker
                  v-model="startToEndDate"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  class="w100"
                  align="right"
                  unlink-panels
                  range-separator="-"
                  start-placeholder="Start Date"
                  end-placeholder="End Date"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item label="eventName" label-width="100px" class="mb5">
                <el-input v-model="postbackReport.eventName" placeholder="eventName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="4" :lg="4" :xl="4" align="right">
              <el-form-item label-width="0px" class="mb5">
                <el-button type="primary" @click="searchListClick(1)" :loading="listLoading"
                  >In Browser</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="listLoading"
          style="width:100%;"
          stripe
          size="mini"
          border
          :data="postbackReportList"
          highlight-current-row
        >
          <el-table-column
            label="Date"
            :fixed="fullWidth <= 768 ? false : 'left'"
            min-width="100"
            :resizable="false"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{ dateChange(scope.row.clickTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="TransactionId"
            prop="transactionId"
            min-width="120"
            align="center"
            :fixed="fullWidth <= 768 ? false : 'left'"
            show-overflow-tooltip
            :resizable="false"
          ></el-table-column>
          <el-table-column
            label="Prod"
            prop="prod"
            align="center"
            :fixed="fullWidth <= 768 ? false : 'left'"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="Payout"
            prop="payoutValue"
            align="center"
            width="70"
          ></el-table-column>
          <el-table-column
            label="Aff Payout"
            prop="affPayoutValue"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column label="TrackCountry" prop="trackCountry" width="90" align="center">
            <template slot="header">
              <span>Track<br />Country</span>
            </template>
          </el-table-column>
          <el-table-column label="Adjusted" min-width="100" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.adjusted == 0">下发</span>
              <span v-else-if="scope.row.adjusted == 1">扣除</span>
              <span v-else></span>
            </template>
          </el-table-column>
          <el-table-column
            label="adjustedType"
            prop="adjustedType"
            min-width="100"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="Source"
            prop="source"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="SourceId"
            prop="sourceId"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            label="Affiliate"
            prop="affiliate"
            min-width="120"
            align="center"
          ></el-table-column>
          <el-table-column
            label="AffiliateId"
            prop="affiliateId"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            label="EventName"
            prop="eventName"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            label="AffiliateSub"
            prop="affiliateSub"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            label="OriginalUrl"
            prop="originalUrl"
            min-width="100"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="RedirectUrl"
            prop="redirectUrl"
            min-width="100"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="Create Time"
            prop="createTime"
            min-width="170"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
        <pagination
          class="block pagePanel"
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pageSize"
          :currentPage="currentPage"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>
<script>
  import PostbackReportCon from '../controllers/report/PostbackReport';

  export default {
    name: 'PostbackReport',
    ...PostbackReportCon,
  };
</script>
<style lang="scss" scoped></style>
<style lang="scss"></style>
