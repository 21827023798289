import { getPostbackReport } from 'api/report';
import Pagination from '@/components/pagination';
import GroupSelect from '@/components/GroupSelect';

import dateUtils from '@/utils/dateutils.js';
import { myMixin } from '@/mixins/mixins.js';
import optionData from '@/assets/js/optionData';
import { mapState, mapActions } from 'vuex';
import { filterObject } from 'utils/utils';

export default {
  mixins: [myMixin],
  components: {
    Pagination,
    GroupSelect,
  },
  data() {
    return {
      listLoading: false,
      pageSize: 20,
      currentPage: 1,
      fullWidth: document.documentElement.clientWidth,
      total: null,
      postbackReportList: [],
      startToEndDate: '',
      timezoneOption: optionData.timezoneOption,
      postbackReport: {
        sourceIds: null,
        affiliateIds: null,
        offerIds: null,
        transactionIds: null,
        fromDate: null,
        toDate: null,
        timezone: 0,
        eventName: '',
      },
      sourceIdArray: [],
    };
  },
  computed: {
    ...mapState('affiliate', {
      affiliatesGroupList: (state) => state.affiliatesGroupList,
      affiliateLoading: (state) => state.affiliateLoading,
    }),
    ...mapState('source', {
      sourcesList: (state) => state.sourcesList,
      sourceLoading: (state) => state.sourceLoading,
    }),
  },
  mounted() {
    //默认选择
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() + 3600 * 1000 * 24);
    this.startToEndDate = [];
    this.startToEndDate[0] = dateUtils.date2StrYMD(end);
    this.startToEndDate[1] = dateUtils.date2StrYMD(start);

    this.getAffiliateList();
    this.getSourceIdsList();
  },
  methods: {
    ...mapActions('affiliate', ['getAffiliateList']),
    ...mapActions('source', ['getSourceIdsList']),
    searchListClick(curPage) {
      if (curPage) {
        this.currentPage = curPage;
      }
      let param = {
        ...this.postbackReport,
        page: this.currentPage,
        pageSize: this.pageSize,
      };
      if (Array.isArray(param.affiliateIds)) {
        param.affiliateIds = param.affiliateIds.join(',');
      }
      if (Array.isArray(param.sourceIds)) {
        param.sourceIds = param.sourceIds.join(',');
      }
      if (Array.isArray(this.startToEndDate) && this.startToEndDate.length === 2) {
        param['fromDate'] = this.startToEndDate[0];
        param['toDate'] = this.startToEndDate[1];
      }
      param = filterObject(param);
      this.listLoading = true;

      getPostbackReport(param).then((response) => {
        this.listLoading = false;
        if (response.code === 200) {
          this.postbackReportList = response.result;
          this.total = response.total;
        } else {
          this.$message.error(response.message);
        }
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.searchListClick();
    },
    dateChange(time) {
      const date = new Date(time),
        Y = date.getFullYear() + '-',
        M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-',
        D = date.getDate() + ' ';
      return Y + M + D;
    },
  },
};
